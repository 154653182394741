import React from "react";

const LoadingScreen = () => {
  return (
    <>
      <div className="divLoader">
        <div className="divInnerLoader">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
