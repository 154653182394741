import { Suspense, lazy } from "react";// use to loading , loading screen until full page is load
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import MainLayout from "../layouts/main";

// config
import { DEFAULT_PATH } from "../config";
import LoadingScreen from "../components/LoadingScreen";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}> 
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/auth',
      element: <MainLayout/>,
      children:[
        {element: <LoginPage/>, path:'login'},
      ]
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "prompt", element: <AshaPromptPage /> },
        { path: "study-protocol", element: <StudyProtocolPage /> },
        { path: "profile-viewer", element: <ProfileViewerPage /> },
        { path: "demo", element: <DemoApp /> },
        { path: "dashboard", element: <DemoDashboard /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp")),
);

const AshaPromptPage = Loadable(
  lazy(() => import("../pages/dashboard/AshaPrompt")),
);

const StudyProtocolPage = Loadable(
  lazy(() => import("../pages/dashboard/StudyProtocol")),
);

const ProfileViewerPage = Loadable(
  lazy(() => import("../pages/dashboard/ProfileViewer")),
);

const SettingsPage = Loadable(
  lazy(() => import("../pages/dashboard/Settings")),
);

const LoginPage = Loadable(
  lazy(() => import("../pages/auth/Login")),
);

const ProfilePage = Loadable(
  lazy(() => import("../pages/dashboard/Profile")),
);

const DemoApp = Loadable(
  lazy(() => import("../pages/dashboard/DemoApp")),
);

const DemoDashboard = Loadable(
  lazy(() => import("../pages/dashboard/DemoDashboard")),
);

const Page404 = Loadable(lazy(() => import("../pages/Page404")));
