import { Navigate, Outlet } from "react-router-dom";
import { Stack } from '@mui/material';
import { useSelector } from "../../redux/store";

const DashboardLayout = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  if (!isAuthenticated) {
    return <Navigate to='/auth/login'/>;
  }

  return (
    <Stack direction='row'>
      <Outlet />
    </Stack>
    
  );
};

export default DashboardLayout;
