import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// define initial state
const initialState = {
    greetingMessage: {},
    conversationHistory: [],
    promptAnswer: ""
}

// create slice
const slice = createSlice({
    name:'conversation',
    initialState,
    reducers:{
        setGreetingMessage(state, action){
            state.greetingMessage = action.payload.greetingMessage;
        },
        setConversationHistory(state, action){
            state.conversationHistory = action.payload.conversationHistory;
        },
        setPromptAnswer(state, action){
            state.promptAnswer = action.payload.promptAnswer;
        },
    }
});

// export reducer
export default slice.reducer;

export function SetGreetingMessage (greetingMessage){
    return async () =>{
        dispatch(slice.actions.setGreetingMessage({
            greetingMessage
        }))
    }
}

export function SetConversationHistory (conversationHistory){
    return async () =>{
        dispatch(slice.actions.setConversationHistory({
            conversationHistory
        }))
    }
}

export function SetPromptAnswer (promptAnswer){
    return async () =>{
        dispatch(slice.actions.setPromptAnswer({
            promptAnswer
        }))
    }
}