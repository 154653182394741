import { Container, Stack } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Logo from "../../assets/Images/login_logo.png";
import { useSelector } from "../../redux/store";
import palette from "../../theme/palette";

const MainLayout = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  if (isAuthenticated) {
    return <Navigate to="/app" />;
  }

  return (
    <>
      <Stack sx={{ pt: 5, height: '100vh', background: `linear-gradient(${palette.light.primary.gradientTop}, ${palette.light.primary.gradientBottom})` }}>
        <Container spacing={5} maxWidth={"lg"}>
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            alignItems={"center"}
          >
            <img style={{ height: 120, width: 180 }} src={Logo} alt="Logo" />
          </Stack>
        </Container>
        <Outlet />
      </Stack>
    </>
  );
};

export default MainLayout;
