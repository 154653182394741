import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// define initial state
const initialState = {
    loader: false,
    messageLoader: false,
    isMicrophone: false,
    isAudioProcessing: false
}

// create slice
const slice = createSlice({
    name:'app',
    initialState,
    reducers:{
        setAppLoader(state, action){
            state.loader = action.payload.loader;
        },
        setMessageLoader(state, action){
            state.messageLoader = action.payload.messageLoader;
        },
        setIsMicrophone(state, action){
            state.isMicrophone = action.payload.isMicrophone;
        },
        setIsAudioProcessing(state, action){
            state.isAudioProcessing = action.payload.isAudioProcessing;
        }
    }
});

// export reducer
export default slice.reducer;

export function SetAppLoader(loader){
    return async () =>{
        dispatch(slice.actions.setAppLoader({
            loader
        }))
    }
}

export function SetMessageLoader(messageLoader){
    return async () =>{
        dispatch(slice.actions.setMessageLoader({
            messageLoader
        }))
    }
}

export function SetIsMicrophone(isMicrophone){
    return async () =>{
        dispatch(slice.actions.setIsMicrophone({
            isMicrophone
        }))
    }
}

export function SetIsAudioProcessing(isAudioProcessing){
    return async () =>{
        dispatch(slice.actions.setIsAudioProcessing({
            isAudioProcessing
        }))
    }
}