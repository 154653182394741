import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// define initial state
const initialState = {
    isAuthenticated: false,
    currentUser: null
}

// create slice
const slice = createSlice({
    name:'user',
    initialState,
    reducers:{
        updateAuthentication(state, action){
            state.isAuthenticated = action.payload.isAuthenticated;
        },
        setCurrentUser(state, action){
            state.currentUser = action.payload.currentUser;
        }
    }
});

// export reducer
export default slice.reducer;

export function UpdateAuthentication (isAuthenticated){
    return async () =>{
        dispatch(slice.actions.updateAuthentication({
            isAuthenticated
        }))
    }
}

export function SetCurrentUser (currentUser){
    return async () =>{
        dispatch(slice.actions.setCurrentUser({
            currentUser
        }))
    }
}