import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// define initial state
const initialState = {
    patientList: [],
    selectedPatient: {},
    appointmentList: {},
    medicationDiaryList: []
}

// create slice
const slice = createSlice({
    name:'patient',
    initialState,
    reducers:{
        setPatientList(state, action){
            state.patientList = action.payload.patientList;
        },
        setSelectedPatient(state, action){
            state.selectedPatient = action.payload.selectedPatient;
        },
        setAppointmentList(state, action){
            state.appointmentList = action.payload.appointmentList;
        },
        setMedicationDiaryList(state, action){
            state.medicationDiaryList = action.payload.medicationDiaryList;
        },
    }
});

// export reducer
export default slice.reducer;

export function SetPatientList (patientList){
    return async () =>{
        dispatch(slice.actions.setPatientList({
            patientList
        }))
    }
}

export function SetSelectedPatient (selectedPatient){
    return async () =>{
        dispatch(slice.actions.setSelectedPatient({
            selectedPatient
        }))
    }
}

export function SetAppointmentList (appointmentList){
    return async () =>{
        dispatch(slice.actions.setAppointmentList({
            appointmentList
        }))
    }
}

export function SetMedicationDiaryList (medicationDiaryList){
    return async () =>{
        dispatch(slice.actions.setMedicationDiaryList({
            medicationDiaryList
        }))
    }
}